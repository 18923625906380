import Utils from "./Utils";
import APIRequest from "./Request";

export default class EngageTrack{
    "use strict";

    static newPageLoad(NavLoc){
        if (typeof window === `undefined` || typeof document === `undefined`){
            return;
        }

        if (EngageTrack.NavLoc === NavLoc){
            return;
        }

        if (EngageTrack.NavLoc !== null){
            EngageTrack.sendData();
        }

        if (!EngageTrack.windowEventInit){
            window.addEventListener('scroll', EngageTrack.logScroll);
            window.addEventListener('unload', EngageTrack.sendData);
            EngageTrack.windowEventInit = true;
        }

        EngageTrack.NavLoc = NavLoc;
        EngageTrack.viewStartTime = Date.now();
        
        EngageTrack.dirNumChange = 0;
        EngageTrack.lastDir = "DOWN";
        EngageTrack.lastScrollPos = EngageTrack.minScroll = EngageTrack.maxScroll = Utils.getWindowScroll().y;
        EngageTrack.screenHeight = window.innerHeight;
        EngageTrack.pageHeight = document.querySelector('html').scrollHeight;
    }

    static logScroll(){
        const scrollY = Utils.getWindowScroll().y;
        const threshold = 20;
        
        if (scrollY > EngageTrack.maxScroll){
            EngageTrack.maxScroll = scrollY;
        }
        if (scrollY < EngageTrack.inScroll){
            EngageTrack.minScroll = scrollY;
        }

        if (EngageTrack.lastDir == "DOWN"){
            // Same direction
            if (scrollY > EngageTrack.lastScrollPos){
                EngageTrack.lastScrollPos = scrollY;
                return;
            }

            // Changed direction
            if (scrollY < (EngageTrack.lastScrollPos - threshold)){
                EngageTrack.dirNumChange++;
                EngageTrack.lastDir = "UP";
                EngageTrack.lastScrollPos = scrollY;
            }
        }else{
            // Same direction
            if (scrollY < EngageTrack.lastScrollPos){
                EngageTrack.lastScrollPos = scrollY;
                return;
            }

            // Changed direction
            if (scrollY > (EngageTrack.lastScrollPos + threshold)){
                EngageTrack.dirNumChange++;
                EngageTrack.lastDir = "DOWN";
                EngageTrack.lastScrollPos = scrollY;
            }
        }
    }

    static sendData(){
        if (EngageTrack.NavLoc === null){
            return;
        }

        console.log(EngageTrack.maxScroll, EngageTrack.minScroll, EngageTrack.screenHeight, EngageTrack.pageHeight);

        // Calculate the percentage of the page that has been viewed
        const viewPerc = ((((EngageTrack.maxScroll - EngageTrack.minScroll) + EngageTrack.screenHeight) / EngageTrack.pageHeight) * 100)

        try {
            let req = new APIRequest("logEngage/", {
                navLoc: EngageTrack.NavLoc,
                viewTime: ((Date.now() - EngageTrack.viewStartTime) / 1000),
                maxScroll: EngageTrack.maxScroll,
                viewPerc: viewPerc,
                scrollDirChanges: EngageTrack.dirNumChange
            }, {
                requestType: "BEACON"
            });

            req.makeRequest();
        } catch (error) {
        }

        // Just in case
        EngageTrack.NavLoc = null;
    }
}

EngageTrack.NavLoc = null;