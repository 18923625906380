import APIRequest from "./Request";
import Utils from "./Utils";

// Wrapper for tracking sends

export default class ViewTrack{
    "use strict";

    static send(NavLoc, Action){
        if (typeof document === `undefined`) {
            return;
        }

        if (Action == "Load" && ViewTrack.curNavLoc == NavLoc){
            return;
        }
        ViewTrack.curNavLoc = NavLoc;
        
        let referrer = document.referrer;
    
        let send = {
            navLoc: NavLoc,
            action: Action,
            referrer: referrer,
            screenWidth: window.innerWidth
        };
    
        // Pull out UTM tags
        const utmTags = ['utm_source', 'utm_campaign'];
        for (let i = 0; i < utmTags.length; i++) {
            const utmKey = utmTags[i];
            const utmValue = Utils.getGETVariable(utmKey);
            
            if (utmValue !== null){
                send[utmKey] = utmValue;
            }
        }
    
        try {
            let req = new APIRequest("logUse/", send, {
                requestType: "BEACON"
            });
        
            req.makeRequest();
        } catch (error) {
            
        }
    }
}