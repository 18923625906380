import React from 'react';

class AsyncLoadLink extends React.Component {
    constructor(props) {
        super(props);

        if (!props.adr || !props.hash || !props.rel){
            throw new Error('Async loaded link needs address, hash and rel');
        }

        this.linkRef = React.createRef();

        this.handleLoad = this.handleLoad.bind(this);
    }

    componentDidMount(){
        this.linkRef.current.href = this.props.adr;
    }

    handleLoad = event => {
        this.linkRef.current.onLoad = null;
        this.linkRef.current.rel = this.props.rel;
    }

    render() {
        return(<>
            <link rel="preload" as="style" ref={this.linkRef} href={this.props.adr} onLoad={this.handleLoad} integrity={this.props.hash} crossOrigin="anonymous"/>
            <noscript><link rel="stylesheet" href={this.props.adr} integrity={this.props.hash} crossOrigin="anonymous"/></noscript>
        </>);
    }
}

AsyncLoadLink.defaultProps = {
    "rel": 'stylesheet'
}
 
export default AsyncLoadLink;