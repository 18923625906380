import React from 'react';
import { Link } from 'gatsby';

import style from './style.module.scss';

// Should be used for every link where possible
// Abstracts framework linking to ensure the correct routing method is performed
class IndyLink extends React.Component {
    constructor(props){
        super(props);

        if (!props.adr){
            throw new Error('Indy link requires an address');
        }

        this.adr = props.adr;

        // Add trailing / to adr if directory
        if (!this.adr.split('/').pop().includes(".") && 
            !this.adr.split('/').pop().includes("#") && 
            this.adr.charAt(this.adr.length - 1) != "/") {
            this.adr = this.adr + "/";
        }

        this.forceNav = this.forceNav.bind(this);
    }

    // Done to prevent Gatsby getting in the way of /user/ routing
    forceNav(e){
        e.preventDefault();
        window.location.assign(this.adr);
    }

    render() {
        let className = style.indyLink;
        if (this.props.className){
            className = className + " " + this.props.className;
        }

        if (this.adr.includes("://")){
            // External link
            return (
                <a id={this.props.id} className={className} href={this.adr} onClick={this.forceNav} target="_blank" rel="nofollow noopener noreferrer">
                    {this.props.children}
                </a>
            );
        }else{
            return (
                <Link id={this.props.id} className={className} to={this.adr}>
                    {this.props.children}
                </Link>
            );
        }
    }
}

export default IndyLink;