var Utils = (function() {
    var getNumEls = function(input){
        if (input instanceof Object){
            return Object.keys(input).length;
        }else if(input instanceof Array){
            return input.length;
        }else{
            throw new Error('Unknown type to get element number');
        }
    }

    var isNumeric = function(val){
        return (!isNaN(val) && val !== "" && val !== undefined)
    }

    // https://stackoverflow.com/questions/6274339/how-can-i-shuffle-an-array
    var shuffle = function(a){
        var j, x, i;
        for (i = a.length - 1; i > 0; i--) {
            j = Math.floor(Math.random() * (i + 1));
            x = a[i];
            a[i] = a[j];
            a[j] = x;
        }
        return a;
    }

    // https://stackoverflow.com/questions/6211613/testing-whether-a-value-is-odd-or-even
    var isEven = function(n) {
        return n % 2 === 0;
    }

    // https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
    var validateEmail = function(email) {
        let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    // https://stackoverflow.com/questions/4338267/validate-phone-number-with-javascript
    var validatePhone = function(phone) {
            let re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
            return re.test(phone);
        }

    var getScreenWidthEM = function(){
        return this.convertPxToEM(window.innerWidth);
    }
    var convertPxToEM = function(input){
        return input / parseFloat(
            getComputedStyle(
            document.querySelector('body')
            )['font-size']
        );
    }

    var getGETVariable = function(parameterName){
        const items = location.search.substr(1).split("&");
        for (let index = 0; index < items.length; index++) {
            const tmp = items[index].split("=");
            if (tmp[0] === parameterName){
                return decodeURIComponent(tmp[1]);
            }
        }

        return null;
    }

    // https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollY
    var getWindowScroll = function(){
        var supportPageOffset = window.pageXOffset !== undefined;
        var isCSS1Compat = ((document.compatMode || "") === "CSS1Compat");

        return{
            x: supportPageOffset ? window.pageXOffset : isCSS1Compat ? document.documentElement.scrollLeft : document.body.scrollLeft,
            y: supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop
        }
    }
  
    return {
        getNumEls: getNumEls,
        isNumeric: isNumeric,
        shuffle: shuffle,
        isEven: isEven,
        validateEmail: validateEmail,
        validatePhone: validatePhone,
        getScreenWidthEM: getScreenWidthEM,
        convertPxToEM: convertPxToEM,
        getGETVariable: getGETVariable,
        getWindowScroll: getWindowScroll
    }
})();
  
export default Utils;