import React from 'react';
import Cookies from 'js-cookie';

import APIRequest from './Request';

// This allows all data retrieval to be centralized
// Components log themselves to be updated when a change occurs. Changes are made via setState to allow React to run as efficiently as it does
// Deep updates / fetching are supported, so that complex retrieval models can be used without issue
export default class DataDriver{
    "use strict";

    // Returns a listener ID that should be used to remove listener
    static attachListenerForStateChange(changeThis, keys){
        if (React.isValidElement(changeThis) || typeof(changeThis) === "function"){
            throw new Error('Data driver listener must be attached to React component');
        }

        if (Array.isArray(keys)){
            let listenersCompile = [];
            for (let i = 0; i < keys.length; i++) {
                listenersCompile.push(DataDriver.attachListenerForStateChange(changeThis, keys[i]));
            }
            return listenersCompile;
        }
            
        let keyStr = keys;

        // Format and check key
        if (typeof(keyStr) !== "string"){
            throw new Error('Key must be string');
        }

        let listenerID = Math.random().toString(36);

        if (!DataDriver.listeners.hasOwnProperty(keyStr)){
            DataDriver.listeners[keyStr] = {};
        }

        // Add listener
        DataDriver.listeners[keyStr][listenerID] = changeThis;

        // Fetch data for key string
        // This will set state if new data retrieved
        DataDriver.fetchData(keyStr);

        return listenerID;
    }

    static removeListener(listenerID){
        if (Array.isArray(listenerID)){
            for (let i = 0; i < listenerID.length; i++) {
                DataDriver.removeListener(listenerID[i]);
            }
            return;
        }

        if (typeof(listenerID) !== "string"){
            throw new Error('ListenID must be string');
        }

        for (let keyStr in DataDriver.listeners) {
            if (DataDriver.listeners.hasOwnProperty(keyStr)) {
                let keyListeners = DataDriver.listeners[keyStr];
                
                for (let testListenerID in keyListeners) {
                    if (keyListeners.hasOwnProperty(testListenerID) && testListenerID === listenerID) {
                        delete DataDriver.listeners[keyStr][listenerID];
                        return;
                    }
                }
            }
        }
    }

    static addFetch(keys, callback){
        let methodID = Math.random().toString(36);

        if (!Array.isArray(keys)){
            keys = [keys];
        }

        for (let i = 0; i < keys.length; i++) {
            DataDriver.fetchMethodMap[keys[i]] = methodID;
        }

        callback.keys = keys;
        DataDriver.fetchMethods[methodID] = callback;
    }

    static refresh(keyStr){
        DataDriver.fetchData(keyStr, true);
    }

    static fetchData(keyStr, force = false){
        const keyPathAr = keyStr.split("->");

        if (!force){
            let value = DataDriver.deepFetch(DataDriver.cache, keyPathAr.slice());
            if (value !== null){
                DataDriver.setStates(keyPathAr);
                return;
            }
        }

        // Fetch fresh data
        let keyPathArIter = keyPathAr.slice();

        // Find closest fetch method to key
        let foundCallback = false;
        for (let pathI = (keyPathAr.length - 1); pathI >= 0; pathI--) {
            const keyPathString = keyPathArIter.join('->');
            if (!DataDriver.queryFetchStatus(keyPathString)){
                // Data fetch already in progress
                // Therefore this function can be completely aborted
                return;
            }

            if (DataDriver.fetchMethodMap.hasOwnProperty(keyPathString)){
                // Found a fetching method. This method is responsible for resolving with value
                foundCallback = DataDriver.fetchMethods[DataDriver.fetchMethodMap[keyPathString]];
                break;
            }

            keyPathArIter.pop();
        }

        if (foundCallback === false){
            throw new Error('Key fetch not recognized: '+keyStr);
        }

        // Find the most specific data fetch for the key path
        DataDriver.queryFetchStatus(foundCallback.keys, false);
        let retProm = new Promise(foundCallback);

        retProm.then(function(values){
            if (values === null){
                throw new Error('Fetched data can\'t be null');
            }
            
            for (let i = 0; i < foundCallback.keys.length; i++) {
                const keyPathString = foundCallback.keys[i];
                
                if (!values.hasOwnProperty(keyPathString)) {
                    throw new Error('Promised key not returned');
                }
            
                // Save returns to cache
                let keyPathArIter = keyPathString.split("->");
                DataDriver.cache = DataDriver.deepUpdate(DataDriver.cache, keyPathArIter.slice(), values[keyPathString]);
                DataDriver.setStates(keyPathArIter.slice());
            }

            DataDriver.queryFetchStatus(foundCallback.keys, true);

        }).catch(function(errorMsg){
            DataDriver.queryFetchStatus(foundCallback.keys, true);
            console.error('Data fetch failure: '+errorMsg);
        });
    }

    // Set and get status of information fetch
    static queryFetchStatus(key, reset = false){
        if (Array.isArray(key)){
            for (let i = 0; i < key.length; i++) {
                DataDriver.queryFetchStatus(key[i], reset);
            }
            return;
        }

        const index = DataDriver.activeFetches.indexOf(key);
        if (index === -1){
            DataDriver.activeFetches.push(key);
            return true;
        }else{
            if (reset){
                DataDriver.activeFetches.splice(index, 1);
            }
            return false;
        }
    }

    static setStates(keyPathAr){
        const keyPathStr = keyPathAr.join('->');
        for (const keyStr in DataDriver.listeners) {
            if (DataDriver.listeners.hasOwnProperty(keyStr)) {
                // See if key string is applicable
                // State set string should start listener key string
                if (keyStr.indexOf(keyPathStr) !== 0){
                    continue;
                }

                const listeners = DataDriver.listeners[keyStr];
                for (let listenerID in listeners) {
                    if (listeners.hasOwnProperty(listenerID)) {
                        let component = listeners[listenerID];
                        
                        // Create state
                        let stateSet = {};
                        stateSet["DD:"+keyStr] = DataDriver.deepFetch(DataDriver.cache, keyStr.split('->'));
        
                        // Set listeners state
                        component.setState(stateSet);
                    }
                }       
            }
        }
    }

    // Finds value in target based on keyPathAr
    static deepFetch(target, keyPathAr){
        let nextKey = keyPathAr.shift();

        if (!target.hasOwnProperty(nextKey)){
            return null;
        }

        if (keyPathAr.length === 0){
            return target[nextKey];
        }else{
            return DataDriver.deepFetch(target[nextKey], keyPathAr);
        }
    }

    // Updates value in target based on keyPathAr
    static deepUpdate(target, keyPathAr, value){
        let nextKey = keyPathAr.shift();

        if (keyPathAr.length === 0){
            target[nextKey] = value;
            return target;
        }

        if (!target.hasOwnProperty(nextKey)){
            target[nextKey] = {};
        }

        target[nextKey] = DataDriver.deepUpdate(target[nextKey], keyPathAr, value);

        return target;
    }
}

DataDriver.cache = {};
DataDriver.listeners = {};
DataDriver.activeFetches = [];
DataDriver.fetchMethods = {};
DataDriver.fetchMethodMap = {};

////////////
/// Fetchers
////////////

// Pricing data
DataDriver.addFetch(["prices", "taxRate", "chargeKeys", "minDaySpend", "payThreshold"], function(resolve, reject){
    let Req = new APIRequest("pricingData/");

    Req.makeRequest().then(function(ret){
        // Parse result
        let cmpl = {};
        let chargeKeys = [];
        for (const chargeKey in ret.Prices) {
            if (ret.Prices.hasOwnProperty(chargeKey)) {
                cmpl[chargeKey] = ret.Prices[chargeKey];
                chargeKeys.push(chargeKey);
            }
        }

        resolve({
            "prices": cmpl, 
            "taxRate": ret.ChargingDetails.taxRate,
            "minDaySpend": ret.ChargingDetails.minChargePerDay,
            "payThreshold": ret.ChargingDetails.payThreshold,
            "chargeKeys": chargeKeys
        });

    }).catch(function(errorMsg){
        reject(errorMsg);
    });
});

// Opt out cookie
DataDriver.addFetch(["optOutPrefSet", "optedOutOf"], function(resolve, reject){
    let isSet;
    let optOut = Cookies.get('MashoomOptOut');
    if (optOut){
        isSet = true;
        switch (optOut) {
            case "ALL":
                optOut = ['PubTrack']; break;
            case "NONE":
                optOut = []; break;
            default:
                optOut = optOut.split(',');
        }
    }else{
        isSet = false;
        optOut = [];
    }

    resolve({
        "optOutPrefSet": isSet, 
        "optedOutOf": optOut
    });
});