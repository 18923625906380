import React from 'react';

import {BASE_URL, SIGNUP_URL, LOGIN_URL } from 'library/constants.js';

import './style.scss';

import IndyLink from 'components/IndyLink';

import Logo from 'media/shared/mashoomLogos/LogoOnDark.inline.svg';

export default function Header(){
    return (
        <header id="titleBar">
          <div id="headerContWrap">
            <div id="logoWrap">
                <IndyLink adr={BASE_URL}>
                    <Logo alt="Mashoom logo"/>
                </IndyLink>
            </div>
            <div id="headerActions">
                <IndyLink id='headerSignup' className='headerAction' adr={SIGNUP_URL}>Sign up</IndyLink>
                <span id='headerActionSpacer'></span>
                <IndyLink id='headerLogin' className='headerAction' adr={LOGIN_URL}>Login</IndyLink>
            </div>
          </div>
        </header>
    );
}