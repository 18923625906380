import React from 'react';
import { CSSTransition } from 'react-transition-group';

import DataDriver from 'library/DataDriver';
import APIRequest from 'library/Request';

import style from "./style.module.scss";

import IndyLink from 'components/IndyLink';

class CookieNotice extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            "DD:optOutPrefSet": true
        }

        this.accept = this.accept.bind(this);
    }

    componentDidMount(){
        this.listenerID = DataDriver.attachListenerForStateChange(this, ["optOutPrefSet"]);
    }
    componentWillUnmount() {
        DataDriver.removeListener(this.listenerID);
    }

    accept(){
        try {
            let req = new APIRequest("changeCookiePref/", {
                type: "ALL",
                action: "OptIn"
            },{
                requestType: "POST"
            });
        
            req.makeRequest().then(function(){
                DataDriver.refresh("optedOutOf");
            });
        } catch (errorMsg) {
            console.error('Opt-in preference change failure: '+errorMsg);
        }
    }

    render(){
        return(
            <CSSTransition 
                in={!this.state['DD:optOutPrefSet']} 
                unmountOnExit
                timeout={1000} 
                classNames={{
                    exitActive: style.onExit
                }}
            >
                <div id={style.container}>
                    <p><span>We only use our own cookies.</span> <span className={style.link} onClick={this.accept} >Accept our cookies</span> or <IndyLink className={style.link} adr="/cookie-policy"> read our opt-out options</IndyLink>.</p>
                </div>
            </CSSTransition>
        )
    }
}

export default CookieNotice;