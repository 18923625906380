import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby"

import moment from 'moment';

import { BASE_URL } from "library/constants";
import AsyncLoadLink from "components/AsyncLoadLink";

export function SEO(props) {
    let {
        headType,
        title,
        description,
        author,
        previewImg,
        previewImgKey,
        createdTS,
        lastUpdatedTS,
        path,
        wordCount,
    } = props;

    let authorTwitterHandle;
    if (author.twitterHandle) {
        if (author.twitterHandle.charAt(0) != "@") {
            throw new Error('Author must be a twitter handle');
        }
        authorTwitterHandle = author.twitterHandle;
    } else {
        authorTwitterHandle = "@mashoomUK"
    }

    // If no verbose preview image provided, use key or default
    if (!previewImg){
        if (previewImgKey){
            previewImg = getPrevImgFromKey(previewImgKey);
        }else{
            previewImg = getPrevImgFromKey("default");
        }
    }else if (previewImgKey){
        throw new Error('Preview image key should not be set when image provided');
    }

    // Check preview image validity
    if (!previewImg.url || !previewImg.alt || !previewImg.height || !previewImg.width) {
        throw new Error('Preview image missing parameter');
    }

    if (path !== "NONE"){
        path = formatPath(path);
    }else{
        path = false;
    }

    previewImg.url = formatPath(previewImg.url);


    /// Create JSON schema
    let JSONSchema = {
        "@context": "http://schema.org",
        "name": title,
        "image": {
            "@type": "ImageObject",
            "url": previewImg.url,
            "height": previewImg.height,
            "width": previewImg.width,
            "caption": previewImg.alt
        }
    };
    if (description){
        JSONSchema.description = description;
    }

    if (path !== false){
        JSONSchema.url = path;
        JSONSchema.mainEntityOfPage = path;
    }

    // Either submissively merged into page, or used as the blog post publisher
    let MashMImg = getPrevImgFromKey("default")
    let MashoomJSON = {
        "@type": "Corporation",
        name: "Mashoom",
        email: "hello@mashoom.co.uk",
        legalName: "Mashoom Ltd.",
        logo: {
            "@type": "ImageObject",
            "url": MashMImg.url,
            "height": MashMImg.height,
            "width": MashMImg.width,
            "caption": MashMImg.alt
        },
        "url": "https://www.mashoom.co.uk",
        "mainEntityOfPage": "https://www.mashoom.co.uk"
    }

    switch (headType) {
        case "BlogPost":
            JSONSchema["@type"] = "BlogPosting";
            JSONSchema.headline = title;
            JSONSchema.inLanguage = "en-GB";

            if (description){
                JSONSchema.abstract = description;
            }

            let authorJSON = {
                "@type": "Person",
                "name": author.name,
                "image": {
                    "@type": "ImageObject",
                    url: formatPath(author.profilePic.publicURL),
                    caption: "A picture of " + author.name
                }
            }
            JSONSchema.author = authorJSON;
            JSONSchema.creator = authorJSON;

            if (wordCount !== 0) {
                JSONSchema.wordCount = wordCount;
            }

            MashoomJSON["@type"] = "Organization";
            JSONSchema.publisher = MashoomJSON;
            break;

        case "DocPost":
            JSONSchema["@type"] = "HowTo";
            JSONSchema.headline = title;
            JSONSchema.inLanguage = "en-GB";

            if (description){
                JSONSchema.abstract = description;
            }

            if (wordCount !== 0) {
                JSONSchema.wordCount = wordCount;
            }

            MashoomJSON["@type"] = "Organization";
            JSONSchema.publisher = MashoomJSON;
            JSONSchema.author = MashoomJSON;
            JSONSchema.creator = MashoomJSON;
            break;

        default:
            for (const key in MashoomJSON) {
                if (MashoomJSON.hasOwnProperty(key) && !JSONSchema.hasOwnProperty(key)) {
                    JSONSchema[key] = MashoomJSON[key];
                }
            }
            break;
    }

    if (createdTS !== 0) {
        let createdString = moment.unix(createdTS).toISOString();
        JSONSchema.dateCreated = createdString;
        JSONSchema.datePublished = createdString;

        if (lastUpdatedTS !== 0) {
            JSONSchema.dateModified = moment.unix(lastUpdatedTS).toISOString();
        }else{
            JSONSchema.dateModified = createdString;
        }
    }

    let meta = [
        {
            property: `og:site_name`,
            content: "Mashoom",
        },
        {
            property: `og:type`,
            content: `website`,
        },
        {
            property: `og:title`,
            content: title,
        },
        {
            property: `og:locale`,
            content: "en_GB",
        },
        {
            property: `og:country-name`,
            content: "GB",
        },
        {
            property: `og:email`,
            content: "hello@mashoom.co.uk",
        },

        {
            property: `og:image`,
            content: previewImg.url
        },
        {
            property: `og:image:alt`,
            content: previewImg.alt
        },
        {
            property: `og:image:height`,
            content: previewImg.height
        },
        {
            property: `og:image:width`,
            content: previewImg.width
        },

        {
            name: `twitter:card`,
            content: `summary`,
        },
        {
            name: `twitter:site`,
            content: `@mashoomUK`,
        },
        {
            name: `twitter:domain`,
            content: `www.mashoom.co.uk`,
        },
        {
            name: `twitter:title`,
            content: title,
        },
        {
            name: `twitter:creator`,
            content: authorTwitterHandle,
        },
        {
            name: `twitter:image`,
            content: previewImg.url,
        },
        {
            name: `twitter:image:alt`,
            content: previewImg.alt,
        }
    ];

    if (description){
        meta.push({
            name: `description`,
            content: description
        });
        meta.push({
            property: `og:description`,
            content: description
        });
        meta.push({
            name: `twitter:description`,
            content: description
        });
    }

    let link = [];
    if (path !== false){
        meta.push({
            property: `og:url`,
            content: path
        });
        link.push(
            {
                "rel": "canonical",
                "href": path
            }
        )
    }

    return (
        <Helmet
            htmlAttributes={{
                "lang": "en"
            }}
            title={title}
            meta={meta}
            link={link}
            script={[{
                type: 'application/ld+json',
                innerHTML: JSON.stringify(JSONSchema)
            }]}
        />
    )
}

SEO.defaultProps = {
    headType: "General",
    title: "Mashoom - The Business Solutions Platform",
    description: "Solutions designed by our users. Existing online pay-per-use modules with unlimited users. Ready to upgrade or build new for your requirements.",
    author: {},
    previewImg: null,
    createdTS: 0,
    lastUpdatedTS: 0,
    path: "NONE",
    wordCount: 0
}

SEO.propTypes = {
    headType: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    author: PropTypes.object,
    previewImg: PropTypes.object,
    createdTS: PropTypes.number,
    lastUpdatedTS: PropTypes.number,
    path: PropTypes.string,
    wordCount: PropTypes.number
}

export function Favicons() {
    return (
        <Helmet
            link={[
                {
                    "rel": "apple-touch-icon",
                    "sizes": "180x180",
                    "href": BASE_URL + "favs/apple-touch-icon.png?v=lk2wENrO70"
                },
                {
                    "rel": "icon",
                    "type": "image/png",
                    "sizes": "32x32",
                    "href": BASE_URL + "favs/favicon-32x32.png?v=lk2wENrO70"
                },
                {
                    "rel": "icon",
                    "type": "image/png",
                    "sizes": "16x16",
                    "href": BASE_URL + "favs/favicon-16x16.png?v=lk2wENrO70"
                },
                {
                    "rel": "manifest",
                    "href": BASE_URL + "manifest.json"
                },
                {
                    "rel": "mask-icon",
                    "href": BASE_URL + "favs/safari-pinned-tab.inline.svg?v=lk2wENrO70",
                    "color": "#0fa2d8"
                },
                {
                    "rel": "shortcut icon",
                    "href": BASE_URL + "favs/favicon.ico?v=lk2wENrO70"
                },
                {
                    "rel": "shortcut icon",
                    "href": BASE_URL + "favs/favicon.ico?v=lk2wENrO70"
                }
            ]}
            meta={[
                {
                    "name": "msapplication-TileColor",
                    "content": "#363837"
                },
                {
                    "name": "msapplication-TileImage",
                    "content": BASE_URL + "favs/mstile-144x144.png?v=lk2wENrO70"
                },
                {
                    "name": "theme-color",
                    "content": "#363837"
                }
            ]}
        />
    );
}

export function HeaderDeps() {
    return null
}

export function HeaderAsyncDeps() {
    return (
        <AsyncLoadLink adr="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" hash="sha384-wvfXpqpZZVQGK6TAh5PVlGOfQNHSoD2xbE+QkPxCAFlNEevoEH3Sl0sibVcOQVnN" />
    )
}

export function HeaderMisc() {
    return (
        <Helmet
            meta={[
                {
                    "charSet": "utf-8"
                },
                {
                    "name": "viewport",
                    "content": "width=device-width, initial-scale=1, viewport-fit=cover"
                },
                {
                    "name": "referrer",
                    "content": "no-referrer"
                }
            ]}
        />
    )
}

export function HeaderNextPrev({nextAdr, prevAdr}) {
    let link = [];
    if (nextAdr){
        link.push({
            "rel": "next",
            "href": formatPath(nextAdr)
        })
    }
    if (prevAdr){
        link.push({
            "rel": "prev",
            "href": formatPath(prevAdr)
        })
    }

    if (link.length == 0){
        return null;
    }

    return <Helmet link={link} />;
}

function formatPath(url) {
    // Add trailing / if directory
    if (!url.split('/').pop().includes(".") && url.charAt(url.length - 1) != "/") {
        url = url + "/";
    }

    // Full URL; no base directory required
    if (url.includes("://")) {
        return url;
    }

    // Remove preceding / if given, as this is in the BASE_URL
    if (url.charAt(0) == "/") {
        url = url.substring(1);
    }
    url = BASE_URL + url;

    return url;
}

function getPrevImgFromKey(key){
    const data = useStaticQuery(graphql`
        query PreviewImgs {
            default: file(sourceInstanceName: {eq: "media"}, relativePath: {eq: "shared/mashoomLogos/MOnLight.png"}) {
                ...PreviewImageFrag
            }
            design: file(sourceInstanceName: {eq: "media"}, relativePath: {eq: "shared/modIcons/Design.png"}) {
                ...PreviewImageFrag
            }
            library: file(sourceInstanceName: {eq: "media"}, relativePath: {eq: "shared/modIcons/Library.png"}) {
                ...PreviewImageFrag
            }
            share: file(sourceInstanceName: {eq: "media"}, relativePath: {eq: "shared/modIcons/Share.png"}) {
                ...PreviewImageFrag
            },
            forms: file(sourceInstanceName: {eq: "media"}, relativePath: {eq: "shared/modIcons/Forms.png"}) {
                ...PreviewImageFrag
            },
            quote: file(sourceInstanceName: {eq: "media"}, relativePath: {eq: "shared/modIcons/Quote.png"}) {
                ...PreviewImageFrag
            },
            stock: file(sourceInstanceName: {eq: "media"}, relativePath: {eq: "shared/modIcons/Stock.png"}) {
                ...PreviewImageFrag
            },
            contact: file(sourceInstanceName: {eq: "media"}, relativePath: {eq: "shared/modIcons/Contact.png"}) {
                ...PreviewImageFrag
            },
            genphl: file(sourceInstanceName: {eq: "media"}, relativePath: {eq: "shared/modIcons/GenPhl.png"}) {
                ...PreviewImageFrag
            },
            locations: file(sourceInstanceName: {eq: "media"}, relativePath: {eq: "shared/modIcons/Locations.png"}) {
                ...PreviewImageFrag
            }
        }

        fragment PreviewImageFrag on File {
            childImageSharp {
                fixed(width: 800, height: 400, fit: CONTAIN, background: "white") {
                    src
                    height
                    width
                }
            }
        }
    `);

    key = key.toLowerCase();

    if (!data.hasOwnProperty(key)){
        throw new Error('Unknown preview image key');
    }

    let img = {
        url: data[key].childImageSharp.fixed.src,
        alt: "Design module icon",
        height: data[key].childImageSharp.fixed.height,
        width: data[key].childImageSharp.fixed.width
    }

    switch (key.toLowerCase()) {
        case "default":
            img.alt = "Mashoom M";
            break;

        case "design":
            img.alt = "Design module icon";
            break;

        case "library":
            img.alt = "Library module icon";
            break;

        case "share":
            img.alt = "Share module icon";
            break;
    }

    return img;
}