import React from 'react';

import './style.scss';

import Header from 'components/Header';
import NavMenu from 'components/NavMenu';
import { Footer } from 'components/Footer';
import { HeaderMisc, HeaderDeps, Favicons, SEO, HeaderAsyncDeps } from 'components/Helmets';
import CookieNotice from 'components/CookieNotice';
import EngageTrack from 'library/EngageTrack';
import ViewTrack from 'library/ViewTrack';

class PageWrap extends React.Component {
    constructor(props) {
        super(props);
        
        if (!props.path){
            throw new Error('Path must be set for page wrap');
        }
        if (!props.noSEO && (!props.path || !props.title)) {
            throw new Error('If default SEO being used, title and path should be set');
        };
    }

    componentDidMount(){
        ViewTrack.send(this.props.path, "Load");
        EngageTrack.newPageLoad(this.props.path);
    }

    render() {
        return (
            <React.StrictMode>
                <HeaderMisc />
                <HeaderDeps />
                <Favicons />
                {!this.props.noSEO &&
                    <SEO 
                        path={this.props.path}
                        title={this.props.title}
                    />
                }
                <HeaderAsyncDeps />
                {!this.props.isLandingPage && 
                    <>
                        <Header />
                        <NavMenu path={this.props.path} />
                    </>
                }
                <main>
                    {this.props.children}
                </main>
                <Footer simple={this.props.isLandingPage}/>
                <CookieNotice />
            </React.StrictMode>
        );
    }
}

export default PageWrap;