import React from 'react';

import { BASE_URL, FB_URL, TWITTER_URL, LINKED_IN_URL } from 'library/constants.js';

import './style.scss';

import IndyLink from 'components/IndyLink';

import Logo from 'media/shared/mashoomLogos/LogoOnDark.inline.svg';

export function Footer({ simple }) {
    const currentYear = new Date().getFullYear();

    return (
        <footer className={simple ? "simpleFooter" : ""}>
            {simple
                ? <div className='cont'>
                    <div><p>Copyright © Mashoom {currentYear}. All rights reserved.</p></div>
                    <div className='links'>
                        <IndyLink adr="/privacy-policy" className="footerLink">Privacy policy</IndyLink>
                        <span>|</span>
                        <IndyLink adr="/terms-and-conditions" className="footerLink">Terms & conditions</IndyLink>
                    </div>
                </div>
                : <>
                    <div id="footerTop">
                        <div id="footerLogoWrap">
                            <IndyLink adr={BASE_URL}>
                                <Logo alt="Mashoom logo" />
                            </IndyLink>
                        </div>
                        <div id="footerLinksWrap">
                            <div className='footerLinkSubWrap'>
                                <div className="footerLinkWrap">
                                    <h4 className="col1">WHO WE ARE</h4>
                                    <IndyLink adr="/" className="footerLink">Home</IndyLink>
                                    <IndyLink adr="/about" className="footerLink">About</IndyLink>
                                    <IndyLink adr="/about/#ContactUs" className="footerLink">Contact</IndyLink>
                                </div>
                                <div className="footerLinkWrap">
                                    <h4 className="col2">OUR PRODUCT</h4>
                                    <IndyLink adr="/modules" className="footerLink">Modules</IndyLink>
                                    <IndyLink adr="/addons" className="footerLink">Add-ons</IndyLink>
                                    <IndyLink adr="/pricing" className="footerLink">Pricing</IndyLink>
                                    <IndyLink adr="/learn" className="footerLink">Learn</IndyLink>
                                </div>
                            </div>
                            <div className='footerLinkSubWrap'>
                                <div className="footerLinkWrap">
                                    <h4 className="col1">THE LEGAL STUFF</h4>
                                    <IndyLink adr="/cookie-policy" className="footerLink">Cookie policy</IndyLink>
                                    <IndyLink adr="/privacy-policy" className="footerLink">Privacy policy</IndyLink>
                                    <IndyLink adr="/terms-and-conditions" className="footerLink">Terms & conditions</IndyLink>
                                </div>
                                <div className="footerLinkWrap">
                                    <h4 className="col2">SOME OTHER STUFF</h4>
                                    <IndyLink adr="/racing" className="footerLink">Racing sponsorship</IndyLink>
                                    <IndyLink adr={FB_URL} className="footerLink">Our Facebook page</IndyLink>
                                    <IndyLink adr={LINKED_IN_URL} className="footerLink">Our LinkedIn profile</IndyLink>
                                    <IndyLink adr={TWITTER_URL} className="footerLink">Our Twitter feed</IndyLink>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="footerBottom">
                        <p>Copyright © Mashoom {currentYear}. All rights reserved.</p>
                    </div>
                </>}
        </footer>
    );
}

Footer.defaultProps = {
    simple: false
}