import { API_URL } from 'library/constants.js';
import Utils from "./Utils";

export default class APIRequest{
    constructor(path, sendVars = {}, options = {}) {
        if (!path){
            throw new Error('Path required');
        }else{
            this.path = path;
        }

        if (!sendVars){
            this.sendVars = {};
        }else{
            this.sendVars = sendVars;
        }

        if (!options){
            this.opts = {};
        }else{
            this.opts = options;
        }

        // Write defaults
        var defaults = {
            requestType: "GET"
        }
        for (var defaultKey in defaults) {
            if (defaults.hasOwnProperty(defaultKey)) {
                if (!this.opts.hasOwnProperty(defaultKey)){
                    this.opts[defaultKey] = defaults[defaultKey];
                }
            }
        }
    }

    makeRequest(){
        // Form headers
        let headers = new Headers([
            ['Content-Type', 'application/json']
        ]);
        let uri = API_URL + this.path;
        let fetchProm;

        let reqType = this.opts.requestType;

        // Use post as fallback to beacon
        if (reqType == "BEACON" && !navigator.sendBeacon){
            reqType = "POST";
        }

        let beaconStatus;
        switch (reqType) {
            case "GET":
                uri = uri + APIRequest.formGetVars(this.sendVars);

                fetchProm = fetch(uri, {
                    method: reqType,
                    headers: headers
                });
                break;

            case "BEACON":
                beaconStatus = navigator.sendBeacon(uri, JSON.stringify(this.sendVars));
                break;
        
            default:
                fetchProm = fetch(uri, {
                    method: reqType,
                    headers: headers,
                    body: JSON.stringify(this.sendVars)
                });
                break;
        }
        
        var retProm = new Promise((resolve, reject) => {
            if (reqType == "BEACON"){
                if (beaconStatus === true){
                    resolve({});
                }else{
                    reject('Beacon send error');
                }
            }else{
                fetchProm.then(function(response){
                    if (!response.ok) {
                        if (response.status === 410){
                            reject("LOGOUT");
                        }else{
                            response.text().then(function(text){
                                reject('API '+response.status+' error\n' + text);
                            });
                        }
                    }else{
                        response.text().then(function(text){
                            if (text === "" || text === "OK"){
                                resolve({});
                            }else{
                                try {
                                    var pars = JSON.parse(text);   
                                } catch (e) {
                                    reject('API error: invalid JSON return');
                                }
                                resolve(pars);
                            }
                        });
                    }
                });
            }
        })

        return retProm;
    }

    static formGetVars(data){
        if (Utils.getNumEls(data) == 0){
            return "";
        }

        let uri = "?";
        
        let key;
        for (key in data) {
            if (data.hasOwnProperty(key)) {
                uri = uri + key + "=" + encodeURIComponent(data[key]) + "&";
            }
        }
        
        uri = uri.slice(0, -1);

        return uri;
    }
}