import React from 'react';
import './style.scss';

import IndyLink from 'components/IndyLink';

const menuItems = {
                "Home": "/",
                "Modules": "/modules",
                "Add-ons": "/addons",
                "Pricing": "/pricing",
                "About": "/about",
                "Learn": "/learn",
                "Blog": "/blog"
};

class NavMenu extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            scroll: 0,
            hasLoaded: false
        }

        this.menuRef = React.createRef();
    }

    componentDidMount() {
        if (typeof window === `undefined`){
            return false;
        }

        let ind = 0;
        let found = false;
        if (!!window && window.location.pathname != "/"){
            for (const title in menuItems) {
                if (menuItems.hasOwnProperty(title)) {
                    const path = menuItems[title];
                    if (this.isThisLocation(path)){
                        found = true;
                        break;
                    }
                }

                ind++;
            }
        }

        if (found === false){
            ind = 0;
        }

        this.menuRef.current.scrollTop = -5 + ind*24;
        
        setTimeout(function(){
            this.setState({
                hasLoaded: true
            });
        }.bind(this), 100);
    }

    listenScrollEvent() {
        const navScroll = this.menuRef.current.scrollTop;
        const height = this.menuRef.current.scrollHeight - this.menuRef.current.clientHeight;
        const scrolled = 0.10 + ((navScroll / height) * 0.8);

        this.setState({
            scroll: scrolled
        })
    }

    isThisLocation(path) {
        if (typeof window === `undefined`){
            return false;
        }

        return !!window && !!path && path !== "/" &&
            (
                window.location.pathname.includes(path) ||
                path === window.location.pathname
            )
    }

    isInActiveView(scroll, index) {
        const lengthOfMenu = Object.keys(menuItems).length;
        const itemLength = 1 / (lengthOfMenu);
        const position = (index + 1) / (lengthOfMenu);
        const focusStart = Math.min(scroll - (1/(lengthOfMenu + 1)) + itemLength, 1);
        const focusEnd = focusStart + itemLength;
        return position == focusStart || (position > focusStart && position < focusEnd);

    }

    renderMenuItem(menuTitle, index, scroll) {
        if (!menuItems.hasOwnProperty(menuTitle)) {
            return null;
        }
        const path = menuItems[menuTitle];
        let MLClassString = "menuLink";
        MLClassString += (this.isThisLocation(path) ? " active" : "");
        MLClassString += (this.isInActiveView(scroll, index) ? " activeViewItem" : "");

        let MCClassString = (this.isInActiveView(scroll, index) ? "activeViewItemContainer" : "");

        return (
            <li key={menuTitle} className={MCClassString}><IndyLink className={MLClassString} adr={path}>{menuTitle}</IndyLink></li>
        );
    }

    render() {
        let classNames = "navBar";
        if (this.state.hasLoaded){
            classNames += " useAni";
        }

        return (
                <nav className={classNames} ref={this.menuRef} name={this.state.scroll} onScroll={this.listenScrollEvent.bind(this)}>
                    <ul>
                        {Object.keys(menuItems).map((item, index) => this.renderMenuItem(item, index, this.state.scroll))}
                    </ul>
                </nav>
        );
    }
}

export default NavMenu;